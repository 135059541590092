import PropTypes from 'prop-types';
import {useEffect, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {paths} from 'src/routes/paths';
import {useRouter} from 'src/routes/hooks';
import {useAuthContext} from 'src/hooks';
import {LoadingScreen} from 'src/components/loading-screen';

/**
 * Commentaire JSX à écrire
 * @return {JSX.Element}
 */
export default function AuthGuard({children}) {
  const router = useRouter();
  const location = useLocation();
  const {isAuthenticated, loading} = useAuthContext();

  const check = useCallback(() => {
    if (!loading && !isAuthenticated) {
      if (!localStorage.getItem('recentlyLoggedOut')) {
        // Stocker l'URL actuelle seulement si l'utilisateur ne vient pas de se déconnecter
        const fullPath = location.pathname + location.search;
        localStorage.setItem('preAuthPath', fullPath);
      } else {
        // Supprimer le marqueur pour les futures vérifications
        localStorage.removeItem('recentlyLoggedOut');
      }
      router.replace(paths.auth.login);
    }
  }, [isAuthenticated, loading, router, location.pathname, location.search]);

  useEffect(() => {
    check();
  }, [check]);

  if (loading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
