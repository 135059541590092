import {useState} from 'react';

// ----------------------------------------------------------------------

/**
 * `useCopyToClipboard` est un hook personnalisé qui gère la copie de textes dans le presse-papiers.
 * Il expose la valeur copiée actuelle et une fonction pour déclencher la copie.
 *
 * @function
 * @return {object} - Un objet contenant la valeur copiée et une fonction pour déclencher la copie.
 * @return {string|null} copiedText - Le texte qui a été copié avec succès dans le presse-papiers, ou null si la copie a échoué ou si rien n'a encore été copié.
 * @return {function} copy - Une fonction qui prend un texte comme argument et tente de le copier dans le presse-papiers.
 *
 * @example
 * const { copiedText, copy } = useCopyToClipboard();
 */
export function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState(null);

  const copy = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return {copiedText, copy};
}
