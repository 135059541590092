import React, {useState, useEffect} from 'react';
import {UserDetailsContext} from './UserDetailsContext';
import {useUser} from 'src/api/Auth/Auth';

export const UserDetailsProvider = ({children}) => {
  const {user, isLoading, isError, mutate} = useUser();
  const [userDetails, setUserDetails] = useState(null);
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const [isPremium, setIsPremium] = useState(false); // Nouvel état pour le statut premium

  const refreshUserDetails = async () => {
    const updatedUser = await mutate();
    if (updatedUser && !isError) {
      const onboardingCompleted = updatedUser.companyDetails.length > 0 ?
        updatedUser.companyDetails[0].onboardingCompleted :
        false;
      const stripeConnected = !!updatedUser.companyDetails[0]?.stripeAccountId;
      const premiumStatus = updatedUser.companyDetails.length > 0 ?
        updatedUser.companyDetails[0].accountType === 'premium' :
        false;

      const updatedUserDetails = {
        ...updatedUser,
        onboardingCompleted,
        isPremium: premiumStatus,
      };

      setUserDetails(updatedUserDetails);
      setIsStripeConnected(stripeConnected);
      setIsPremium(premiumStatus);
    }
  };

  useEffect(() => {
    if (user && !isError) {
      const onboardingCompleted = user.companyDetails.length > 0 ?
        user.companyDetails[0].onboardingCompleted :
        false;
      const stripeConnected = !!user.companyDetails[0]?.stripeAccountId;
      const premiumStatus = user.companyDetails.length > 0 ?
        user.companyDetails[0].accountType === 'premium' :
        false;

      const initialUserDetails = {
        ...user,
        onboardingCompleted,
        isPremium: premiumStatus,
      };

      setUserDetails(initialUserDetails);
      setIsStripeConnected(stripeConnected);
      setIsPremium(premiumStatus);
    }
  }, [user, isError]);

  const value = {
    userDetails,
    isStripeConnected,
    isPremium, // Exposition du statut premium
    isLoading,
    isError,
    refreshUserDetails,
    // Helper function pour vérifier l'accès aux fonctionnalités premium
    checkPremiumAccess: (callback) => {
      if (!isPremium) {
        // Tu peux personnaliser le message d'erreur ou utiliser une modale MUI
        alert('Cette fonctionnalité nécessite un compte premium');
        return false;
      }
      return true;
    },
  };

  return (
    <UserDetailsContext.Provider value={value}>
      {children}
    </UserDetailsContext.Provider>
  );
};
