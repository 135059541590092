import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthContext} from 'src/hooks';
import {checkAccess} from 'src/utils/rolesConfig';
import {paths} from 'src/routes/paths';

export const useRedirectAfterAuth = () => {
  const navigate = useNavigate();
  const {isAuthenticated, userRole, loading, isOnboardingCompleted} = useAuthContext();

  useEffect(() => {
    if (!loading && isAuthenticated) {
      const preAuthPath = localStorage.getItem('preAuthPath');

      if (preAuthPath) {
        navigate(preAuthPath);
        localStorage.removeItem('preAuthPath');
      } else {
        if (checkAccess(userRole, paths.admin.root)) {
          navigate(paths.admin.root, {replace: true});
        } else if (checkAccess(userRole, paths.app.root)) {
          // Redirection générique pour les utilisateurs ayant accès à l'application
          if (isOnboardingCompleted) {
            navigate(paths.app.dashboard, {replace: true});
          } else {
            navigate(paths.app.onboarding, {replace: true});
          }
        } else {
          navigate('/', {replace: true}); // Redirection par défaut pour les rôles non autorisés
        }
      }
    }
  }, [isAuthenticated, userRole, loading, isOnboardingCompleted, navigate]);
};
