import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useAuthContext} from 'src/hooks';
import {useRouter} from 'src/routes/hooks';

/**
 * Commentaire JSX à écrire
 * @return {JSX.Element}
 */
export default function RoleBasedGuard({roles, children}) {
  const {userRole} = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    // Vérification de l'autorisation
    if (roles && !roles.includes(userRole)) {
      // Redirection vers une page 403 Forbidden
      router.push('/403');
    }
  }, [userRole, roles, router]);

  return <>{children}</>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
};
