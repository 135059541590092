import {useContext} from 'react';
import {UserDetailsContext} from 'src/contexts/user-details-context';

export const useUserDetailsContext = () => {
  const context = useContext(UserDetailsContext);

  if (!context) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider');
  }

  return context;
};
