import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthContext} from 'src/hooks';
import {SplashScreen} from 'src/components/loading-screen';

const OnboardingGuard = ({children, requireOnboardingCompleted}) => {
  const {isOnboardingCompleted, loading} = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (requireOnboardingCompleted && !isOnboardingCompleted) {
        navigate('/dashboard/onboarding');
      } else if (!requireOnboardingCompleted && isOnboardingCompleted) {
        navigate('/dashboard');
      }
    }
  }, [isOnboardingCompleted, loading, navigate, requireOnboardingCompleted]);

  if (loading) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default OnboardingGuard;
