/**
 * Vérifie si le localStorage est disponible et utilisable dans le navigateur.
 *
 * Cette fonction tente de sauvegarder et ensuite de supprimer une clé de test dans le localStorage.
 * Elle est utilisée pour s'assurer que le localStorage est fonctionnel avant d'y accéder pour stocker ou récupérer des données.
 *
 * @return {boolean} Renvoie true si le localStorage est disponible, false sinon.
 */
export function localStorageAvailable() {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Récupère une valeur stockée dans le localStorage.
 *
 * Cette fonction vérifie d'abord si le localStorage est disponible à l'aide de la fonction localStorageAvailable.
 * Si c'est le cas, elle récupère la valeur associée à la clé spécifiée. Si la clé n'existe pas, une valeur par défaut est retournée.
 *
 * @param {string} key La clé de l'élément à récupérer du localStorage.
 * @param {string} [defaultValue=''] La valeur par défaut à retourner si la clé n'existe pas dans le localStorage.
 * @return {string} La valeur récupérée du localStorage ou la valeur par défaut si la clé n'existe pas.
 */
export function localStorageGetItem(key, defaultValue = '') {
  const storageAvailable = localStorageAvailable();

  let value;

  if (storageAvailable) {
    value = localStorage.getItem(key) || defaultValue;
  }

  return value;
}
