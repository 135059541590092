import React from 'react';
import {useTheme, alpha} from '@mui/material/styles';
import {Avatar, Box, Stack, Typography, Divider, IconButton} from '@mui/material';
import {useUserDetailsContext} from 'src/hooks';
import Label from 'src/components/label';
import {Link} from 'react-router-dom';
import {paths} from 'src/routes/paths';
import Iconify from 'src/components/iconify';

export default function NavUpgrade() {
  const theme = useTheme();
  const {userDetails} = useUserDetailsContext();

  const isOnboardingCompleted = userDetails?.companyDetails?.[0]?.onboardingCompleted;

  const getInitials = (firstName = '', lastName = '') =>
    `${(firstName || '')[0] || ''}${(lastName || '')[0] || ''}`.toUpperCase();

  const getRoleConfig = (role) => {
    switch (role) {
      case 'CompanyAdmin':
        return {
          label: 'Admin',
          color: theme.palette.roles.admin.main,
          lighter: theme.palette.roles.admin.lighter,
          icon: 'mdi:shield-crown-outline',
        };
      case 'CompanyUser':
        return {
          label: 'Utilisateur',
          color: theme.palette.roles.user.main,
          lighter: theme.palette.roles.user.lighter,
          icon: 'mdi:account-outline',
        };
      default:
        return {
          label: role,
          color: theme.palette.grey[500],
          lighter: theme.palette.grey[200],
          icon: 'mdi:account-question-outline',
        };
    }
  };

  const roleConfig = getRoleConfig(userDetails?.role);

  if (!isOnboardingCompleted) {
    return null;
  }

  return (
    <Stack sx={{px: 2, textAlign: 'center'}}>
      {/* Section principale */}
      <Stack sx={{py: 3}} alignItems="center">
        <Box sx={{position: 'relative'}}>
          <Avatar
            sx={{
              'width': 48,
              'height': 48,
              'bgcolor': userDetails?.photoURL ? 'transparent' : roleConfig.color,
              'color': userDetails?.photoURL ? 'inherit' : theme.palette.common.white,
              'fontSize': '1.1rem',
              'fontWeight': 600,
              'transition': 'transform 0.2s ease-in-out',
              'border': `2px solid ${theme.palette.background.paper}`,
              'boxShadow': `0 0 0 1px ${alpha(roleConfig.color, 0.1)}`,
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: `0 0 0 2px ${alpha(roleConfig.color, 0.2)}`,
              },
            }}
          >
            {userDetails?.photoURL ? (
              <Box
                component="img"
                src={userDetails.photoURL}
                alt={userDetails.firstName}
                sx={{width: '100%', height: '100%'}}
              />
            ) : (
              getInitials(userDetails?.firstName, userDetails?.lastName)
            )}
          </Avatar>

          {userDetails?.role === 'CompanyAdmin' && (
            <Label
              color="primary"
              variant="soft"
              sx={{
                top: -6,
                left: 40,
                height: 20,
                position: 'absolute',
                bgcolor: roleConfig.lighter,
                color: roleConfig.color,
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                px: 1,
                borderBottomLeftRadius: 2,
              }}
            >
              <Iconify icon={roleConfig.icon} width={12} />
              {roleConfig.label}
            </Label>
          )}
        </Box>

        {/* Le reste du composant reste identique */}
        <Stack spacing={0.5} sx={{mt: 1.5}}>
          <Link
            to={paths.app.userAccount}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                fontWeight: 600,
                letterSpacing: '0.5px',
              }}
            >
              {userDetails?.firstName}
            </Typography>

            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              justifyContent="center"
            >
              <Iconify
                icon="solar:letter-bold"
                width={12}
                sx={{color: 'text.secondary'}}
              />
              <Typography
                variant="body2"
                noWrap
                sx={{
                  color: 'text.secondary',
                  fontSize: '0.75rem',
                }}
              >
                {userDetails?.email}
              </Typography>
            </Stack>
          </Link>
        </Stack>
      </Stack>

      {/* Divider */}
      <Divider sx={{borderColor: alpha(theme.palette.divider, 0.32)}} />

      {/* Boutons d'action rapide */}
      <Stack direction="row" justifyContent="center" spacing={1} sx={{py: 1.5}}>
        <IconButton
          component="a"
          href="mailto:support@brydge.app"
          size="small"
          sx={{
            'width': 32,
            'height': 32,
            'color': 'text.secondary',
            '&:hover': {
              bgcolor: alpha(theme.palette.primary.main, 0.08),
              color: theme.palette.primary.main,
            },
          }}
        >
          <Iconify icon="solar:question-circle-bold" width={20} />
        </IconButton>

        <IconButton
          component={Link}
          to={paths.app.userAccount}
          size="small"
          sx={{
            'width': 32,
            'height': 32,
            'color': 'text.secondary',
            '&:hover': {
              bgcolor: alpha(theme.palette.primary.main, 0.08),
              color: theme.palette.primary.main,
            },
          }}
        >
          <Iconify icon="solar:settings-bold" width={20} />
        </IconButton>
      </Stack>
    </Stack>
  );
}
