import React from 'react';
import {m} from 'framer-motion';
// @mui
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import {useRouter} from 'src/routes/hooks';
import {paths} from 'src/routes/paths';
// auth
import {useAuthContext, useUserDetailsContext} from 'src/hooks';
// components
import {varHover} from 'src/components/animate';
import CustomPopover, {usePopover} from 'src/components/custom-popover';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Accueil',
    linkTo: paths.admin.root,
  },
/*   {
    label: 'Mon Compte',
    linkTo: paths.admin.userAccount,
  }, */
  // {
  //   label: 'Paramètres',
  //   linkTo: '/#2',
  // },
];

// ----------------------------------------------------------------------

/**
 * AccountPopover affiche un popover contenant des informations sur l'utilisateur connecté
 * ainsi que des options de navigation liées au compte utilisateur.
 * @return {JSX.Element}
 */
export default function AccountPopover() {
  const router = useRouter();
  const {logout} = useAuthContext();
  const popover = usePopover();

  const {userDetails} = useUserDetailsContext();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Erreur lors de la déconnexion :', error);
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={userDetails?.photoURL}
          alt={`${userDetails?.firstName} ${userDetails?.lastName}`}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {userDetails ? userDetails.firstName.charAt(0).toUpperCase() : ''}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 200, p: 0}}>
        <Box sx={{p: 2, pb: 1.5}}>
          <Typography variant="subtitle2" noWrap>
            {userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : 'Loading...'}
          </Typography>

          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {userDetails?.email || 'Loading...'}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed'}} />

        <Stack sx={{p: 1}}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{borderStyle: 'dashed'}} />

        <MenuItem
          onClick={handleLogout}
          sx={{m: 1, fontWeight: 'fontWeightBold', color: 'error.main'}}
        >
          Déconnexion
        </MenuItem>
      </CustomPopover>
    </>
  );
}
