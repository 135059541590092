import {useLocation, matchPath} from 'react-router-dom';

/**
 * Hook personnalisé permettant de déterminer si un lien de navigation est actif.
 *
 * @param {string} path - Le chemin à comparer avec l'emplacement actuel.
 * @param {boolean} deep - Indique si la correspondance doit être profonde (true) ou normale (false).
 * @return {JSX.Element} Élément JSX retourné par le hook personnalisé `useActiveLink`.
 */
export function useActiveLink(path, deep = true) {
  const {pathname} = useLocation();

  const normalActive = path ? !!matchPath({path, end: true}, pathname) : false;

  const deepActive = path ? !!matchPath({path, end: false}, pathname) : false;

  return deep ? deepActive : normalActive;
}
