import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// Guards
import {AuthGuard, RoleBasedGuard, OnboardingGuard} from 'src/guards';
// Layouts
import DashboardLayout from 'src/layouts/app';
// Components
import {LoadingScreen} from 'src/components/loading-screen';
// Roles & Paths
import {paths} from 'src/routes/paths';
import {roles, routeRoles} from 'src/utils/rolesConfig'; // Import des rôles
// Context
import {AuthProvider} from 'src/contexts/auth-context';
import {UserDetailsProvider} from 'src/contexts/user-details-context';

// Lazy-loaded Pages
const OnboardingPage = lazy(() => import('src/pages/app/onboarding/view/OnboardingPage'));
const DashboardPage = lazy(() => import('src/pages/app/dashboard/view/DashboardPage'));
const ReportingPage = lazy(() => import('src/pages/app/reporting/view/ReportingPage'));
const TransactionsPage = lazy(() => import('src/pages/app/gift-voucher/transactions/view/TransactionsPage'));
const UserAccountPage = lazy(() => import('src/pages/app/account/view/UserAccountPage'));
const CompanyPage = lazy(() => import('src/pages/app/company/view/CompanyPage'));
const UsersPage = lazy(() => import('src/pages/app/users/view/UsersPage'));
const InvoicesPage = lazy(() => import('src/pages/app/invoices/view/InvoicesPage'));
const GiftVoucherTypes = lazy(() => import('src/pages/app/gift-voucher/types/views/TypesPage'));
const GiftVouchersTypeCreate = lazy(() => import('src/pages/app/gift-voucher/types/views/TypeCreatePage'));
const GiftVouchersTypeEdit = lazy(() => import('src/pages/app/gift-voucher/types/views/TypeEditPage'));
const GiftVouchersManage = lazy(() => import('src/pages/app/gift-voucher/manage/view/ManagePage'));
const GiftVouchersDetails = lazy(() => import('src/pages/app/gift-voucher/details/view/DetailsPage'));
const GiftVouchersSearch = lazy(() => import('src/pages/app/gift-voucher/search/view/SearchPage'));

export const appRoutes = [
  {
    path: '/dashboard',
    element: (
      <AuthProvider>
        <UserDetailsProvider>
          <AuthGuard>
            <RoleBasedGuard roles={routeRoles[paths.app.root]}>
              <OnboardingGuard requireOnboardingCompleted={true}>
                <DashboardLayout>
                  <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                  </Suspense>
                </DashboardLayout>
              </OnboardingGuard>
            </RoleBasedGuard>
          </AuthGuard>
        </UserDetailsProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: 'onboarding',
        element: <OnboardingGuard requireOnboardingCompleted={false}><OnboardingPage /></OnboardingGuard>,
      },
      {
        path: '',
        element: <DashboardPage />,
        index: true,
      },
      {
        path: 'reporting',
        element:
        <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}>
          <ReportingPage />
        </RoleBasedGuard>,
      },
      {
        path: 'user-account',
        element: <UserAccountPage />,
      },
      {
        path: 'company',
        element:
        <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}>
          <CompanyPage />
        </RoleBasedGuard>,
      },
      {
        path: 'gift-voucher',
        children: [
          {index: true, element: <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}><GiftVoucherTypes /></RoleBasedGuard>},
          {path: 'types', element: <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}><GiftVoucherTypes /></RoleBasedGuard>},
          {path: 'types/create/:type', element: <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}><GiftVouchersTypeCreate /></RoleBasedGuard>},
          {path: 'types/edit/:type/:id', element: <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}><GiftVouchersTypeEdit /></RoleBasedGuard>},
          {path: 'manage', element: <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}><GiftVouchersManage /></RoleBasedGuard>},
          {path: 'details/:id', element: <GiftVouchersDetails />},
          {path: 'transactions', element: <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}><TransactionsPage /></RoleBasedGuard>},
          {path: 'search', element: <GiftVouchersSearch />},
        ],
      },
      {
        path: 'users',
        element:
        <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}>
          <UsersPage />
        </RoleBasedGuard>,
      },
      {
        path: 'invoices',
        element:
        <RoleBasedGuard roles={[roles.COMPANY_ADMIN]}>
          <InvoicesPage />
        </RoleBasedGuard>,
      },
    ],
  },
];
