import {paths} from 'src/routes/paths';

export const roles = {
  APP_ADMIN: 'AppAdmin',
  APP_USER: 'AppUser',
  COMPANY_ADMIN: 'CompanyAdmin',
  COMPANY_USER: 'CompanyUser',
};

export const routeRoles = {
  [paths.admin.root]: [roles.APP_ADMIN, roles.APP_USER],
  [paths.app.root]: [roles.COMPANY_ADMIN, roles.COMPANY_USER],
};

export const checkAccess = (role, route) => {
  // Vérifie si le rôle fourni a accès à la route spécifiée
  return routeRoles[route]?.includes(role);
};
