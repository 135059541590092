import {Navigate, useRoutes} from 'react-router-dom';
import {PATH_AFTER_LOGIN} from 'src/ConfigGlobal';
import {mainRoutes} from './Main';
import {authRoutes} from './Auth';
import {appRoutes} from './App';
import {adminRoutes} from './Admin';
import {publicRoutes} from './Public';

/**
 * Composant de routage principal de l'application.
 * Ce composant utilise React Router pour définir les itinéraires (routes) de l'application.
 * Il redirige vers la page de connexion par défaut lorsqu'aucun chemin spécifique n'est correspondant.
 * Il inclut des itinéraires pour l'authentification, le tableau de bord utilisateur, le tableau de bord administrateur,
 * les routes principales de l'application, et une redirection vers une page 404 en cas de correspondance de chemin non trouvée.
 * @return {JSX.Element}
 */
export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard User routes
    ...appRoutes,

    // Dashboard Admin routes
    ...adminRoutes,

    // Main routes
    ...mainRoutes,

    // Routes publiques des établissements
    ...publicRoutes,

    // No match 404
    {path: '*', element: <Navigate to="/404" replace />},
  ]);
}
