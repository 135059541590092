import {useMemo} from 'react';
import {useParams as _useParams} from 'react-router-dom';

/**
 * Hook personnalisé permettant d'obtenir les paramètres de l'URL à partir de React Router.
 * Utilise `useParams` de React Router et optimise la performance en utilisant `useMemo`.
 *
 * @return {JSX.Element} Élément JSX retourné par le hook personnalisé `useParams`.
 */
export function useParams() {
  const params = _useParams();

  return useMemo(() => params, [params]);
}
