import React, {useState, useCallback, useEffect} from 'react';
import {AuthContext} from './AuthContext';
import {useLogout, useVerifyToken} from 'src/api/Auth/Auth';

export const AuthProvider = ({children}) => {
  const [authState, setAuthState] = useState(() => {
    const storedAuthState = localStorage.getItem('authState');
    return storedAuthState ? JSON.parse(storedAuthState) : {
      isAuthenticated: false,
      userRole: null,
      userId: null,
      emailVerified: false,
      isOnboardingCompleted: false,
      loading: false,
    };
  });

  const logoutHook = useLogout();
  const verifyToken = useVerifyToken();

  const setAuthInfo = useCallback(({userId, role, emailVerified, isOnboardingCompleted}) => {
    const newState = {
      isAuthenticated: true,
      userRole: role,
      userId,
      emailVerified,
      isOnboardingCompleted,
      loading: false,
    };
    localStorage.setItem('authState', JSON.stringify(newState));
    setAuthState(newState);
  }, []);

  const logout = useCallback(() => {
    logoutHook();
    localStorage.setItem('recentlyLoggedOut', 'true');
    localStorage.removeItem('authState');
    setAuthState({
      isAuthenticated: false,
      userRole: null,
      userId: null,
      emailVerified: null,
      isOnboardingCompleted: null,
      loading: false,
    });
  }, [logoutHook]);

  useEffect(() => {
    if (authState.isAuthenticated) {
      verifyToken();
    }
  }, [authState.isAuthenticated, verifyToken]);

  return (
    <AuthContext.Provider value={{...authState, setAuthInfo, logout}}>
      {children}
    </AuthContext.Provider>
  );
};
