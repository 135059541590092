import {createContext} from 'react';

// ----------------------------------------------------------------------

export const UserDetailsContext = createContext({
  userDetails: null,
  isLoading: true,
  isError: false,
  mutate: () => {},
});
