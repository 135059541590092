import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';

// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';

// components
import {SplashScreen} from 'src/components/loading-screen';
// context
import {AuthProvider} from 'src/contexts/auth-context';
import {UserDetailsProvider} from 'src/contexts/user-details-context';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('src/pages/auth/login/view/LoginPage'));
const RegisterPage = lazy(() => import('src/pages/auth/register/view/RegisterPage'));
const RequestVerificationPage = lazy(() => import('src/pages/auth/verify-email/view/RequestVerificationPage'));
const EmailVerificationPage = lazy(() => import('src/pages/auth/verify-email/view/EmailVerificationPage'));
const RequestPasswordResetPage = lazy(() => import('src/pages/auth/reset-password/view/RequestPasswordResetPage'));
const ResetPasswordPage = lazy(() => import('src/pages/auth/reset-password/view/ResetPasswordPage'));

// ----------------------------------------------------------------------

const emailVerificationRoute = {
  path: 'verify-email/:token',
  element: (
    <AuthClassicLayout>
      <EmailVerificationPage />
    </AuthClassicLayout>
  ),
};

const requestVerificationRoute = {
  path: 'request-verification',
  element: (
    <AuthClassicLayout>
      <RequestVerificationPage />
    </AuthClassicLayout>
  ),
};

const requestResetPasswordRoute = {
  path: 'request-reset-password',
  element: (
    <AuthClassicLayout>
      <RequestPasswordResetPage />
    </AuthClassicLayout>
  ),
};

const resetPasswordRoute = {
  path: 'reset-password/:token',
  element: (
    <AuthClassicLayout>
      <ResetPasswordPage />
    </AuthClassicLayout>
  ),
};

const authJwt = {
  path: '',
  element: (
    <AuthProvider>
      <UserDetailsProvider>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </UserDetailsProvider>
    </AuthProvider>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title={`Embarquez votre établissement vers le futur avec ${process.env.REACT_APP_NAME} 🚀`}>
          <RegisterPage />
        </AuthClassicLayout>
      ),
    },
    emailVerificationRoute,
    requestVerificationRoute,
    requestResetPasswordRoute,
    resetPasswordRoute,
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
