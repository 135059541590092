import PropTypes from 'prop-types';
import {forwardRef} from 'react';
// @mui
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import {RouterLink} from 'src/routes/components';

// ----------------------------------------------------------------------
/**
 * Logo component displays the logo of the application.
 * The logo can either be a small logo or a full logo, and can optionally be wrapped in a link.
 *
 * @component
 * @example
 * <Logo disabledLink={true} isSmall={true} sx={{marginTop: '10px'}} />
 *
 * @param {object} props - Props for the component.
 * @param {boolean} [props.disabledLink=false] - Determines if the logo should be wrapped in a link or not.
 * @param {object} [props.sx] - Additional styles to be applied to the logo.
 * @param {boolean} [props.isSmall=false] - Determines if the logo to be shown should be small or full.
 * @param {Ref} ref - React ref to forward.
 *
 * @returns {JSX.Element}
 */
const Logo = forwardRef(({disabledLink = false, sx, isSmall = false, ...other}, ref) => {
  // Local logo (public folder)
  // -------------------------------------------------------
  const logo = isSmall ? (
    <Box
      component="img"
      // Change src url to remove folder color ('blue') to reset default logo
      src="/logo/logo.svg"
      sx={{width: 40, height: 40, cursor: 'pointer', ...sx}}
    />
  ) : (
    <Box
      component="img"
      // Change src url to remove folder color ('blue') to reset default logo
      src="/logo/logo_full.svg"
      sx={{width: 140, height: 64, cursor: 'pointer', ...sx}}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{display: 'contents'}}>
      {logo}
    </Link>
  );
});

Logo.displayName = 'Logo';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  isSmall: PropTypes.bool,
};

export default Logo;
