import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {paths} from 'src/routes/paths';
import {useRouter} from 'src/routes/hooks';
import {useAuthContext} from 'src/hooks';

// ----------------------------------------------------------------------

/**
 * Commentaire JSX à écrire
 * @return {JSX.Element}
 */
export default function GuestGuard({children}) {
  const router = useRouter();
  const {isAuthenticated} = useAuthContext();

  useEffect(() => {
    if (!isAuthenticated) {
      // Redirige les utilisateurs non authentifiés vers la page de connexion
      router.replace(paths.auth.login);
    }
  }, [isAuthenticated, router]);

  return <>{children}</>;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};
