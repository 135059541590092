import axios from 'axios';
import {HOST_API} from 'src/ConfigGlobal';

const axiosInstance = axios.create({
  baseURL: HOST_API,
  withCredentials: true,
});

let isRefreshing = false;
let refreshSubscribers = [];

async function refreshToken() {
  try {
    const response = await axiosInstance.post('/api/auth/refresh-token');
    onRefreshed(response.data.accessToken);
    isRefreshing = false;
    return response;
  } catch (error) {
    isRefreshing = false;
    refreshSubscribers = [];
    console.error('Error refreshing token:', error.response ? error.response.data : error.message);
    handleLogout(); // Déconnecter l'utilisateur si le rafraîchissement échoue
    throw error;
  }
}

function onRefreshed(token) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback);
}

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // Handle 401 errors
      if (error.response && error.response.status === 401) {
        // Directly log out if token is missing and refresh cannot proceed
        if (error.response.data.message === 'Authentification échouée: Token manquant') {
          handleLogout();
          return Promise.reject(error);
        }

        if (!originalRequest._retry) {
          originalRequest._retry = true;

          if (!isRefreshing) {
            isRefreshing = true;

            try {
              const response = await refreshToken();
              originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken;
              return axiosInstance(originalRequest);
            } catch (refreshError) {
              handleLogout();
              return Promise.reject(refreshError);
            }
          } else {
            return new Promise((resolve, reject) => {
              subscribeTokenRefresh((token) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(axiosInstance(originalRequest));
              });
            });
          }
        } else {
          handleLogout(); // Déconnecter si la requête a déjà été réessayée une fois
          return Promise.reject(error);
        }
      }

      // Handle 403 errors and logout
      if (error.response && error.response.status === 403) {
        const errorCode = error.response.data.code;
        if (['TOKEN_INVALID', 'TOKEN_EXPIRED', 'USER_NOT_FOUND', 'TOKEN_NOT_FOUND', 'TOKEN_MISSING'].includes(errorCode)) {
          handleLogout();
        }
      }

      return Promise.reject(error);
    },
);

function handleLogout() {
  localStorage.removeItem('authState'); // Suppression de l'état d'authentification du localStorage
}

export const endpoints = {
  auth: {
    login: '/api/auth/login',
    logout: '/api/auth/logout',
    register: '/api/auth/register',
    verifyEmail: (token) => `/api/auth/verify-email/${token}`,
    user: '/api/auth/user',
    refreshToken: '/api/auth/refresh-token',
    verifyToken: '/api/auth/verify-token',
    requestResetPassword: '/api/auth/request-reset-password',
    resetPassword: '/api/auth/reset-password',
    verifyResetToken: (token) => `/api/auth/verify-reset-token?token=${token}`,
  },
  app: {
    account: {
      details: '/api/app/account/details',
    },
    user: {
      create: '/api/app/user/create-user',
      update: (userId) => `/api/app/user/update-user/${userId}`,
      updateAsAdmin: (userId) => `/api/app/user/update-user-admin/${userId}`,
      getUsersByAccount: (accountId) => `/api/app/user/${accountId}/users`,
      toggleGiftVoucherPurchaseNotifications: (userId) => `/api/app/user/notifications/gift-vouchers/purchase/${userId}`,
      changePassword: '/api/app/user/change-password',
      toggleActive: (userId) => `/api/app/user/toggle-active/${userId}`,
    },
    company: {
      details: '/api/app/company/details',
      update: '/api/app/company/update',
      publicDetails: '/api/app/company/public/details',
      stripeConnect: `/api/app/stripe/company/:id/stripe-connect`,
    },
    giftVoucherType: {
      create: '/api/app/gift-voucher-type/create',
      update: '/api/app/gift-voucher-type/update',
      delete: '/api/app/gift-voucher-type/delete',
      deleteMultiple: '/api/app/gift-voucher-type/delete-multiple',
      all: '/api/app/gift-voucher-type/all',
      details: '/api/app/gift-voucher-type/details',
      stats: '/api/app/gift-voucher-type/stats',
    },
    giftVoucher: {
      all: '/api/app/gift-voucher/all',
      details: '/api/app/gift-voucher/details',
      createManual: '/api/app/gift-voucher/create-manual/:companyId',
      update: '/api/app/gift-voucher/update',
      search: '/api/app/gift-voucher/search',
      stats: {
        detailed: '/api/app/gift-voucher/stats/detailed/:id',
        periodic: '/api/app/gift-voucher/stats/periodic/:id',
        history: '/api/app/gift-voucher/stats/history/:id',
        percentage: '/api/app/gift-voucher/stats/percentage/:id',
        used: '/api/app/gift-voucher/stats/used/:id',
        status: '/api/app/gift-voucher/stats/status/:id',
        type: '/api/app/gift-voucher/stats/type/:id',
      },
    },
    transaction: {
      details: '/api/app/transaction/details/:id',
      all: '/api/app/transaction/all',
      reportPDF: '/api/app/transaction/report-pdf',
    },
    invoice: {
      all: '/api/app/invoice/all/:companyId',
      lastTen: '/api/app/invoice/last-ten/:companyId',
    },
  },
  public: {
    company: {
      details: '/api/public/company/details',
    },
    giftVoucherType: {
      all: '/api/public/gift-voucher-type/all',
      details: '/api/public/gift-voucher-type/details/:companyId/:giftVoucherTypeId',
    },
    giftVoucher: {
      createPaymentIntent: '/api/public/gift-voucher/create-payment-intent',
      createOrder: '/api/public/gift-voucher/create-gift-voucher',
      updateOrder: '/api/public/gift-voucher/update-gift-voucher',
      validateOrder: '/api/public/gift-voucher/validate-gift-voucher',
      transactionDetails: '/api/public/gift-voucher/transaction-details/:transactionId',
    },
    commission: {
      calculate: '/api/public/commission/calculate/:companyId/:amount',
    },
    maintenance: {
      check: '/api/public/maintenance/check',
    },
  },
  admin: {
    accounts: '/api/admin/accounts',
    users: '/api/admin/users',
    companies: '/api/admin/companies',
    accountCreate: '/api/admin/account/create',
    adminCreate: '/api/admin/admin/create',
    giftVoucherTypes: {
      all: '/api/admin/gift-voucher-types/all',
    },
    giftVoucher: {
      all: '/api/admin/gift-voucher/all',
      details: '/api/admin/gift-voucher/details',
    },
    companies: {
      all: '/api/admin/companies/all',
      details: (id) => `/api/admin/companies/details/${id}`,
      update: (id) => `/api/admin/companies/update/${id}`,
      stats: '/api/admin/companies/stats',
      delete: (id) => `/api/admin/companies/delete/${id}`,
    },
  },
};

export default axiosInstance;
