import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Tooltip,
  IconButton,
  Stack,
  Divider,
  alpha,
  useTheme,
  Box,
} from '@mui/material';
import {useUserDetailsContext} from 'src/hooks';
import {useCopyToClipboard} from 'src/hooks/useCopyToClipboard';
import {useSnackbar} from 'src/components/snackbar';
import {FRONTEND_URL} from 'src/ConfigGlobal';
import {paths} from 'src/routes/paths';
import Iconify from 'src/components/iconify';

export default function SalesPageLink() {
  const theme = useTheme();
  const {userDetails} = useUserDetailsContext();
  const {copy} = useCopyToClipboard();
  const {enqueueSnackbar} = useSnackbar();

  const isOnboardingCompleted = userDetails?.onboardingCompleted;
  const publicSlug = userDetails?.companyDetails?.[0]?.slug;
  const stripeAccountId = userDetails?.companyDetails?.[0]?.stripeAccountId;
  const publicUrl = `${FRONTEND_URL}${paths.company.root}/${publicSlug}`;

  const isStripeConnected = stripeAccountId && stripeAccountId.length > 0;

  const onCopy = useCallback(() => {
    copy(publicUrl);
    enqueueSnackbar('Lien copié !', {variant: 'success'});
  }, [copy, enqueueSnackbar, publicUrl]);

  if (!isOnboardingCompleted || !publicSlug) {
    return null;
  }

  const statusColor = isStripeConnected ? theme.palette.success.main : theme.palette.error.main;
  const tooltipText = isStripeConnected ?
    'Compte Stripe connecté, votre page est fonctionnelle.' :
    'Compte Stripe non connecté. Veuillez connecter votre compte pour activer la page de vente.';

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      sx={{
        'position': 'relative',
        'px': 2,
        'height': 40,
        'borderRadius': 1,
        'bgcolor': (theme) => alpha(theme.palette.primary.main, 0.04),
        'transition': 'all 0.2s ease-in-out',
        'cursor': 'default',
        '&:hover': {
          'bgcolor': (theme) => alpha(theme.palette.primary.main, 0.08),
          'transform': 'translateY(-1px)',
          'boxShadow': `0 4px 8px -2px ${alpha(theme.palette.primary.main, 0.16)}`,
          '& .icon-container': {
            transform: 'scale(1.05)',
          },
        },
      }}
    >
      {/* Indicateur d'état en ligne avec Tooltip */}
      <Tooltip
        title={tooltipText}
        arrow
        placement="bottom"
        enterDelay={200}
      >
        <Box
          sx={{
            width: 6,
            height: 6,
            borderRadius: '50%',
            bgcolor: statusColor,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            position: 'absolute',
            top: 8,
            left: 8,
            cursor: 'help',
          }}
        />
      </Tooltip>

      <Tooltip
        title={tooltipText}
        arrow
        placement="bottom"
        enterDelay={200}
      >
        <Box
          className="icon-container"
          sx={{
            display: 'flex',
            alignItems: 'center',
            transition: 'transform 0.2s ease',
            cursor: 'help',
          }}
        >
          <Iconify
            icon="solar:shop-2-bold-duotone"
            width={22}
            sx={{
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Tooltip>

      <Typography
        variant="subtitle2"
        sx={{
          ml: 1,
          color: theme.palette.text.primary,
          fontWeight: 600,
          letterSpacing: '0.015em',
        }}
      >
        Ma page de vente
      </Typography>

      <Divider
        orientation="vertical"
        flexItem
        sx={{
          mx: 1,
          borderStyle: 'dashed',
          opacity: 0.4,
          borderColor: (theme) => alpha(theme.palette.primary.main, 0.3),
        }}
      />

      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          '& .MuiIconButton-root': {
            transition: 'all 0.2s ease',
            backdropFilter: 'blur(8px)',
          },
          '& .MuiIconButton-root:hover': {
            transform: 'translateY(-1px)',
            boxShadow: `0 4px 8px -2px ${alpha(theme.palette.primary.main, 0.2)}`,
          },
        }}
      >
        <Tooltip
          title="Copier le lien"
          arrow
          placement="bottom"
          enterDelay={200}
        >
          <IconButton
            onClick={onCopy}
            size="small"
            sx={{
              'width': 30,
              'height': 30,
              'color': theme.palette.primary.main,
              'bgcolor': (theme) => alpha(theme.palette.primary.main, 0.04),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.12),
              },
            }}
          >
            <Iconify icon="eva:copy-fill" width={18} />
          </IconButton>
        </Tooltip>

        <Tooltip
          title="Ouvrir dans un nouvel onglet"
          arrow
          placement="bottom"
          enterDelay={200}
        >
          <IconButton
            component="a"
            href={publicUrl}
            target="_blank"
            size="small"
            sx={{
              'width': 30,
              'height': 30,
              'color': theme.palette.primary.main,
              'bgcolor': (theme) => alpha(theme.palette.primary.main, 0.04),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.12),
              },
            }}
          >
            <Iconify icon="eva:external-link-outline" width={18} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

SalesPageLink.propTypes = {
  publicSlug: PropTypes.string,
  publicUrl: PropTypes.string,
};
