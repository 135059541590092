import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import {HeaderSimple as Header} from '../_common';
import StripeAlert from '../_common/stripe-alert';

// ----------------------------------------------------------------------

export default function CompactLayout({children}) {
  return (
    <>
      <Header />

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 400,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>

      {/* StripeAlert intégré dans CompactLayout */}
      <StripeAlert />
    </>
  );
}

CompactLayout.propTypes = {
  children: PropTypes.node,
};
