import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
// utils
import {localStorageGetItem} from 'src/utils/storageAvailable';
// config
import {defaultLang} from './config-lang';
// translations
import translationEn from './langs/en.json';
import translationFr from './langs/fr.json';
import translationVi from './langs/vi.json';
import translationCn from './langs/cn.json';
import translationAr from './langs/ar.json';

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {translation: translationEn},
        fr: {translation: translationFr},
        vi: {translation: translationVi},
        cn: {translation: translationCn},
        ar: {translation: translationAr},
      },
      lng,
      fallbackLng: 'fr', // ou defaultLang.value si tu veux une autre langue par défaut
      debug: false,
      ns: ['translation'],
      defaultNS: 'translation',
      interpolation: {
        escapeValue: false,
      },
    });

export default i18n;
