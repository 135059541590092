import {useMemo} from 'react';
import {paths} from 'src/routes/paths';
import {Icon} from '@iconify/react';
import {useUserDetailsContext} from 'src/hooks';
import {roles} from 'src/utils/rolesConfig'; // Assurez-vous que ce fichier contient vos définitions de rôles

export function useNavData() {
  const {userDetails} = useUserDetailsContext();
  const currentCompany = userDetails && userDetails.companyDetails && userDetails.companyDetails.length > 0 ?
    userDetails.companyDetails[0] :
    null;
  const isOnboardingCompleted = currentCompany?.onboardingCompleted;
  const userRole = userDetails?.role;

  const data = useMemo(() => {
    const baseMenu = []; // Initialiser le tableau de baseMenu vide

    // Conditionnellement ajouter "Onboarding" si onboarding n'est pas complété
    if (!isOnboardingCompleted) {
      baseMenu.push({
        subheader: 'Bienvenue',
        items: [
          {
            title: 'Configuration initiale',
            path: paths.onboarding.root,
            icon: <Icon icon="mdi:progress-check" height="24" />,
          },
        ],
      });
    }

    // Conditionnellement ajouter "Mon établissement" si onboarding est complété
    if (isOnboardingCompleted) {
      baseMenu.push({
        subheader: 'tableau de bord',
        items: [
          {
            title: 'Dashboard',
            path: paths.app.root,
            icon: <Icon icon="clarity:dashboard-line" height="24" />,
          },
          // Vérifie le rôle uniquement pour l'accès à "Rapports"
          ...(userRole === roles.COMPANY_ADMIN ? [{
            title: 'Rapports',
            path: paths.app.reporting,
            icon: <Icon icon="fluent-mdl2:report-document" height="24" />,
          }] : []),
        ],
      });

      if (userRole === roles.COMPANY_ADMIN) {
        baseMenu.push({
          subheader: 'Bons cadeaux',
          items: [
            {
              title: 'Catalogue',
              path: paths.app.giftVoucher.types.list,
              icon: <Icon icon="solar:clipboard-list-linear" height="24" />,
            },
            {
              title: 'Ventes',
              path: paths.app.giftVoucher.manage,
              icon: <Icon icon="solar:gift-outline" height="24" />,
            },
            {
              title: 'Recherche',
              path: paths.app.giftVoucher.search,
              icon: <Icon icon="solar:bill-check-linear" height="24" />,
            },
            {
              title: 'Transactions',
              path: paths.app.giftVoucher.transactions,
              icon: <Icon icon="uil:transaction" height="24" />,
            },
          ],
        });
      } else {
        // Utilisateur non admin, afficher seulement le menu "Validation"
        baseMenu.push({
          subheader: 'Bons cadeaux',
          items: [
            {
              title: 'Validation',
              path: paths.app.giftVoucher.search,
              icon: <Icon icon="solar:bill-check-linear" height="24" />,
            },
          ],
        });
      }

      // Ajouter conditionnellement la section "Administration" si l'utilisateur a le rôle COMPANY_ADMIN
      if (userRole === roles.COMPANY_ADMIN) {
        baseMenu.push({
          subheader: 'Administration',
          items: [
            {
              title: 'Mon établissement',
              path: paths.app.company,
              icon: <Icon icon="mdi:company" height="24" />,
            },
            {
              title: 'Utilisateurs',
              path: paths.app.users,
              icon: <Icon icon="ph:users-three" height="24" />,
            },
            {
              title: 'Factures',
              path: paths.app.invoices,
              icon: <Icon icon="iconamoon:invoice-light" height="24" />,
            },
          ],
        });
      }
    }

    return baseMenu;
  }, [isOnboardingCompleted, userRole]); // S'assurer que useMemo se ré-exécute quand isOnboardingCompleted ou userRole change

  return data;
}
