import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {useSnackbar} from 'src/components/snackbar/';
import {useUserDetailsContext} from 'src/hooks';

const STRIPE_CONNECT_URL = `${process.env.REACT_APP_FRONTEND_URL}/dashboard/company?tab=payments`;

export default function StripeAlert() {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {userDetails, isStripeConnected, refreshUserDetails} = useUserDetailsContext();
  const isOnboardingCompleted = userDetails?.onboardingCompleted;
  const [snackbarDisplayed, setSnackbarDisplayed] = useState(false);

  useEffect(() => {
    // Fonction pour gérer la mise à jour de la connexion Stripe via localStorage
    const handleStorageEvent = async (event) => {
      if (event.key === 'stripeConnected' && event.newValue === 'true') {
        localStorage.removeItem('stripeConnected');

        // Rafraîchit les informations utilisateur pour obtenir la mise à jour de connexion Stripe
        await refreshUserDetails();

        // Affiche une notification de succès pour la connexion
        enqueueSnackbar('Connexion à Stripe réussie !', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    };

    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [enqueueSnackbar, refreshUserDetails]);

  useEffect(() => {
    // Si Stripe n'est pas connecté et la snackbar n'a pas été affichée
    if (isOnboardingCompleted && !isStripeConnected && !snackbarDisplayed) {
      const snackbarKey = enqueueSnackbar(
          'Stripe est requis pour vendre vos bons cadeaux.',
          {
            variant: 'stripe',
            action: (key) => (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  window.location.href = STRIPE_CONNECT_URL;
                }}
              >
              Connecter Stripe
              </Button>
            ),
            autoHideDuration: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
      );
      setSnackbarDisplayed(snackbarKey); // Enregistre que la snackbar est affichée
    }

    // Ferme la snackbar et arrête le suivi si Stripe est connecté
    if (isStripeConnected && snackbarDisplayed) {
      closeSnackbar(snackbarDisplayed); // Ferme la snackbar
      setSnackbarDisplayed(true); // Marque l'affichage comme permanent pour arrêter le suivi
    }
  }, [isOnboardingCompleted, isStripeConnected, enqueueSnackbar, closeSnackbar, snackbarDisplayed]);

  return null;
}

StripeAlert.propTypes = {
  stripeAccountId: PropTypes.string,
};
